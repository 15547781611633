<template>
  <div>
    <div class="d-flex justify-content-between">
      <h3>Transmission Webhooks</h3>
      <b-button class="text-muted small font-weight-bold p-0 " variant="none" align="right" @click="$refs['manual-trigger-info-modal'].show()">
        <span  class="small">
          <b-icon icon="info-circle" scale="1" class="mr-25"/>
          You can also trigger a Flux with a Request directly into our Engine - <u>check it here</u>
        </span>
      </b-button>
    </div>

    <b-modal
      ref="manual-trigger-info-modal"
      centered
      size="lg"
      hide-footer
    >
    <template #modal-title>
      <span class="text-success">FiqOn</span> - Manual Trigger info
    </template>
    
    <p class="mb-25">
      Here's how you can trigger a <b>Flux Execution</b> with a <b>Post Request</b> directly into the <b>Engine</b>:
    </p>
      
      <div class="trigger-info-container">
        <h4 class="mt-50 mb-0 d-flex align-items-center">
          <span class="info-url">
            
            <b-badge variant="favorite" class="mr-50">
              <span class="text-black">POST</span>
            </b-badge>
            <span>https://instance.fique.online/event/sync</span>
          </span>
        </h4>
        
        <div class="inner-container mt-1 " v-for="header in Object.keys(MANUAL_TRIGGER_INFO)" >
          <span class="font-weight-bold">
            {{header}}:
          </span>
          <hr class="mt-25 mb-50 border-top">
          <div class="ml-50 key-value-info">
            
            <template v-for="key in Object.keys(MANUAL_TRIGGER_INFO[header])"> 

              <div>
                <b-icon icon="circle-fill" scale="0.3" variant="success" class="mr-25"/>
                <span class="">{{key}}</span>
                <b-icon icon="arrow-right-short" variant="success" shift-v="-1" scale="1.2" class="mx-50"/>
                <span>{{MANUAL_TRIGGER_INFO[header][key]}}
                </span>
              </div>
            </template>

          </div>
        </div>
      
      </div>
      

      <div align="right" class="mx-50 mt-50">
        <b-link variant="none" class="small font-weight-bold text-secondary" target="_blank" href="https://youtu.be/dQw4w9WgXcQ">
          
          Check the Documentation for further info
        </b-link>
      </div>

    </b-modal>

    <!-- CREATE WEBHOOK MODAL ===================== -->
    <b-modal 
      id="add-webhook-modal" 
      centered title="Add Webhook" 
      ok-variant="success" 
      cancel-variant="danger" 
      @ok="createWebhook" 
      ok-title="Confirm" 
      @hidden="resetModal()" 
      ref="create-webhook-modal"
      noCloseOnBackdrop
      @hide="((e)=>{ if (modalInfo.isSaving) e.preventDefault()})"
    >
      <form action="">
        <b-form-group label="Name:">
          <!-- <span class="d-block mb-25">Name:</span> -->
          <b-form-input placeholder="Name of Webhook..." v-model="modalInfo.newWebhookName" required  :state="modalInfo.states.name" @blur="checkInputState('name')" @input="checkInputState('name')"/>
          
        </b-form-group>
        <!-- <b-form-select :options="modalInfo.transmissionServices" v-if="Array.isArray(modalInfo.transmissionServices)" label-field="base_path" v-model="modalInfo.selectedService">

        </b-form-select> -->
        <div class="my-1">
          <b-form-group label="Service:" :state="modalInfo.states.service">
            <v-select v-if="modalInfo.transmissionServices" :options="modalInfo.transmissionServices" v-model="modalInfo.selectedService" label="identifier" @input="loadEvents(); checkInputState('service') " :clearable="false" :searchable="false"  :state="modalInfo.newWebhookName.length>0" />  
            <b-skeleton v-else height="40px"/>
          </b-form-group>
        </div>

        <b-collapse :visible="!!modalInfo.selectedService" class="">
          <b-form-group label="Event:" :state="modalInfo.states.event">
            <v-select :options="modalInfo.transmissionEvents" v-model="modalInfo.selectedEvent" label="identifier" :clearable="false" v-if="modalInfo.transmissionEvents" :searchable="false" @input="checkInputState('event')" />
            <b-skeleton v-else height="40px"/>
          </b-form-group>
        </b-collapse>
      </form>

      <template #modal-ok >
        <b-spinner small v-if="modalInfo.isSaving"/>
        <span v-else>Confirm</span>
      </template>
    
   </b-modal>

   <b-modal
    ref="item-action-modal" 
    centered 
    @hide="((e) =>{ if (itemModalInfo.loading) e.preventDefault()})"
    @ok="confirmModalOpertaion()"
  >

    <template #modal-title>
      {{itemModalInfo.title}}
    </template>
    <template #modal-ok>
      <div>
        <template v-if="!itemModalInfo.loading">
          Confirm
        </template>
        <template v-else>
          <b-spinner small/>
        </template>
      </div>
    </template>

    <p> {{itemModalInfo.content[0]}}<b class="text-success">{{itemModalInfo.content[1]}}</b>{{itemModalInfo.content[2]}} </p>

   </b-modal>
    <!-- ========================================= -->


    <div class="d-flex justify-content-between">
      <div class="mt-25 d-flex align-items-end mb-1">
        <span class="mr-50 mb-25"><b>Search: </b></span>
        <b-form-input class="w-100" size="sm" v-model="table.searchTerm"/>
      </div>
      <div>
        <b-button variant="success" class="my-1" @click="openCreateWebhookModal()" :disabled="userCantEdit">
          <b-icon icon="plus" scale="1.4"/> 
          Add Webhook
        </b-button>
      </div> 
    </div>

    
    <!--========= TABLE ========================================================== -->
    <div v-if="webhooks" class="rounded overflow-hidden">
      <b-table 
        hover 
        :items="webhooks"
        :fields="table.fields" 
        sort-icon-left
        :per-page="table.rowsPerPage"
        :current-page="table.currentPage"
        :filter="table.searchTerm"
        @row-clicked="openWebhookInfo"
        v-if="webhooks"
        show-empty
        class="rounded"
        empty-filtered-text="No matching Webhooks"
      >
        <template #cell(enum_status)="data">
          <b-badge  :variant="getStatusInfo(data.item.enum_status.id)[1]" pill>{{ $t(getStatusInfo(data.item.enum_status.id)[0]) }}</b-badge>
        </template>

        <template #cell(created_at)="data">
          {{formatLLL(data.item.created_at)}}
          <span class="text-secondary d-block small"><b>Last updated:</b> {{formatLLL(data.item.updated_at)}}</span>
        </template>
        
        <template #cell(webhook_actions)="data">
          <div class="d-flex justify-content-end aling-items-center fixed-width-opts">
            <div class="d-flex align-items-center animate__animated animate__fadeInRight animate__faster" v-if="itemOptsOn==data.item.id" >
                          
              <b-tooltip :target="'status-btn-'+data.item.id" triggers="hover">
                Toggle Webhook status
              </b-tooltip>
              <b-button 
                :id="'status-btn-'+data.item.id" 
                class="py-25 px-50 d-inline-block mr-1 animate__animated animate__rotateIn  animate__faster" 
                variant="outline-warning"  
                @click="openItemModal('status',data.item)"
                :disabled="userCantEdit"
              >
                <b-icon icon="power" scale="0.9"/>
              </b-button>

              <b-tooltip :target="'delete-btn-'+data.item.id" triggers="hover">
                Delete Webhook
              </b-tooltip>      
              <b-button
                :id="'delete-btn-'+data.item.id" 
                class="py-25 px-50 d-inline-block mr-50 animate__animated animate__rotateIn   animate__faster" 
                variant="outline-danger" 
                @click="openItemModal('delete',data.item)"
                :disabled="userCantEdit"
              >
                <b-icon icon="trash" scale="0.9" />
              </b-button>
            </div>

            <div align="right">
              <b-button variant="none" class="mr-0 px-50" @click="toggleItemOptions(data.item.id)">
                <b-icon icon="three-dots-vertical" scale="1.3"/>
              </b-button>
            </div>

          </div>
        </template>
        
        <template #empty>
          <h4 class="text-center text-secondary my-2">No Webhooks yet</h4>
        </template>

      </b-table>
      
      <div class="d-flex justify-content-center">
        <b-pagination
          v-model="table.currentPage" 
          :total-rows="webhooks.length" 
          :per-page="table.rowsPerPage"
          class="mb-1"
        >

        </b-pagination>
      </div>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="10"
        :columns="5"
      />
    </div>
    <!-- ========= END OF TABLE =================================================== -->

  </div>
</template>

<script>
import BaseModal from "@/layouts/components/BaseModal.vue";
import custom from "@/custom";
import { makeToast } from "@/layouts/components/Popups.js";
import ScopedVars from "@/layouts/components/Transmission/ScopedVars.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import webhookStatus from '@/custom/class/Enum/WebhookStatus.js'
import vSelect from 'vue-select'

import {
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BSkeleton,
  BTabs,
  BTab,
  BCardText,
  VBTooltip,
  BTable,
  BBadge,
  BPopover,
  BCollapse,
  BModal,
  BFormSelect,
  BSpinner,
  BPagination,
  BSkeletonTable,
  BTooltip,
  BLink,
} from "bootstrap-vue";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BaseModal,
    BSkeleton,
    VBTooltip,
    ScopedVars,
    BTable,
    BBadge,
    BPopover,
    BCollapse,
    BModal,
    BFormSelect,
    vSelect,
    BSpinner,
    BPagination,
    BSkeletonTable,
    BTooltip,
    BLink,
  },
  data() {
    return {
      table:{
        fields: [
          {key: 'identifier', label: 'Name', sortable: true,},
          {key: 'service.identifier', label: 'Service', sortable: true,},
          {key: 'event.event_identifier', label: 'Event', sortable: true,},
          {key: 'enum_status', label: 'Status', sortable: true,},
          {key: 'created_at', label: 'created at', sortable: true,},
          {key: 'webhook_actions', label: '', sortable: false}
        ],
        currentPage: 1,
        rowsPerPage: 10,
        searchTerm: ""
      },
      modalInfo:{
        newWebhookName: "",
        transmissionServices: undefined,
        transmissionEvents: undefined,
        selectedService: null,
        selectedEvent: null,
        isSaving: false,
        states: {
          name: undefined,
          service: undefined,
          event: undefined,
        }
      },
      itemOptsOn: null,
      itemModalInfo:{
        title: "",
        content: "",
        loading: false,
        item: null,
        operation: null,
      },


      MANUAL_TRIGGER_INFO:{
        "Headers":{
          "X-Transmission" : "Group {{transmission_identifier}} "
        },
        "Body Fields":{
          "token": "{{service_token}}",
          "sent_from": "{{sender_service_identifier}}",
          "event": "{{event_identifier}}",
          "can_receive": "{{receiving_service_identifiers(array)}}",
          "data": "{{data_to_send_into_flux}}",
        }
      },
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    ...mapGetters('transmissionWebhook', ['getWebhooks']),
    webhooks(){
      return this.getWebhooks;
    },
    formCreateWebhookValidity(){
      let r = true
      if (!this.modalInfo.selectedEvent || !this.modalInfo.newWebhookName.length){
        r = false      
      }
      return r;
    },
    userCantEdit(){
      return !this.$can('create')
    }
  
  },
  methods: {
    init() {
      this.fetchWebhooks();
    },
  fetchWebhooks() {
    this.$store
      .dispatch("transmissionWebhook/getWebhooksByTransmission", this.$route.params.transmissionID)
      .then((response) => {

      })
      .catch((error) => {
        console.error(error);
      });
  
    },
    openCreateWebhookModal(){
      this.$refs['create-webhook-modal'].show();

      this.$store
        .dispatch("getServicesByTransmissionGroup", {transmissionID: this.$route.params.transmissionID})
        .then((res)=> {
          this.modalInfo.transmissionServices = res.items
        })
        .catch((err)=>{
          console.error(err)
        })  
    },
    createWebhook(bvModalEvent){

      this.checkInputState('name');
      this.checkInputState('service');
      this.checkInputState('event');

      if (!this.formCreateWebhookValidity || this.modalInfo.isSaving){
        bvModalEvent.preventDefault();
        return
      }
      
      let payload = {
        "identifier": this.modalInfo.newWebhookName,
        "service_id": parseInt(this.modalInfo.selectedService.id),
        "event_id": parseInt(this.modalInfo.selectedEvent.id)
      }

      this.modalInfo.isSaving = true;
      this.$store
        .dispatch("transmissionWebhook/insertWebhookInTransmission", {transmissionID: this.$route.params.transmissionID , data: payload})
        .then((res)=> {
          makeToast({
            title: "Success!",
            text: "Webhook created successfully",
            variant: "success",
            icon: "CheckIcon",
          });
          this.modalInfo.isSaving = false;
          this.$refs['create-webhook-modal'].hide();
        })
        .catch((err)=>{
          console.error(err)
          makeToast({
            title: this.$t("common.toast.error.title"),
            text: this.$t('common.toast.error.message'),
            variant: "danger",
            icon: "XIcon",
          });
        })
        .finally(()=> {
          this.modalInfo.isSaving = false;
        })
        

    },
    loadEvents(){
      this.modalInfo.selectedEvent = undefined
      this.modalInfo.transmissionEvents = undefined

      this.$store
        .dispatch("getEventsByService", {transmissionID: this.$route.params.transmissionID , serviceId: this.modalInfo.selectedService.id})
        .then((res)=> {
          this.modalInfo.transmissionEvents = res
        })
        .catch((err)=>{
          console.error(err)
        })
    },

    resetModal(){
      this.modalInfo.newWebhookName = "",
      this.modalInfo.transmissionServices = undefined
      this.modalInfo.transmissionEvents = undefined
      this.modalInfo.selectedEvent = undefined
      this.modalInfo.selectedService = undefined
    },
    openWebhookInfo(item,index,event){
      const transmissionID = this.$route.params.transmissionID
      const webhookID = item.id
      // console.log(`opening info on: Webhook ${JSON.stringify(item.id)} `)
      this.$router.push(`/transmission/${transmissionID}/webhook-info/${webhookID}`)

    },
    onCopy() {
      makeToast(custom.successMessages.onCopy);
    },
    formatLLL(date){
      return moment(date).format('LLL')
    },
    getStatusInfo(id){
      let items = new webhookStatus().items;

      let r = items.find( el => el.id == id);
      return [r.label, r.variant]
    },
    toggleItemOptions(id){
      if (this.itemOptsOn == id){
        this.itemOptsOn = ""
      } else {
        this.itemOptsOn = id
      }
    },
    openItemModal(operation, item){
      this.itemModalInfo.item = item;
      this.itemModalInfo.operation = operation
      switch (operation){
        case 'status':
          this.itemModalInfo.title = "Toggle Webhook Status"
          if (item.enum_status.id == 2){
            this.itemModalInfo.content = ['Make the Webhook ',item.identifier,' inactive?']
          }
          if (item.enum_status.id == 1){
            this.itemModalInfo.content = ['Make the Webhook ',item.identifier,' active?']
          }
          break;
        case 'delete':
          this.itemModalInfo.title = "Delete Webhook"
          this.itemModalInfo.content = ['Confirm deletion of the Webhook ', item.identifier ,'?']
          break;
      }
      this.$refs['item-action-modal'].show()
    },
    confirmModalOpertaion(){
      switch (this.itemModalInfo.operation){
        case 'status':
          let value;
          if(this.itemModalInfo.item.enum_status.id==1){
            value = 2
          } else if(this.itemModalInfo.item.enum_status.id==2){
            value = 1
          }
          const data = {
            new_value: value
          }
          this.toggleWebhookStatus(data);
          break;
        case 'delete':
          this.deleteWebhook();
          break;
      }
    },
    checkInputState(field){
      let value
      switch (field) {
        case 'name':
          value = this.modalInfo.newWebhookName
          break;
        case 'service':
          value = this.modalInfo.selectedService
          break;
        case 'event':
          value = this.modalInfo.selectedEvent
          break;
      }
      this.modalInfo.states[field] = !!value ? undefined : false 
    },
    toggleWebhookStatus(data){
      this.itemModalInfo.loading = true;

      this.$store
      .dispatch("transmissionWebhook/changeWebhookStatus", {transmissionID: this.$route.params.transmissionID, webhookID: this.itemModalInfo.item.id, data: data})
      .then((resp) => {
        makeToast({
          title: 'Success!',
          text: 'Webhook Status updated successfully',
          variant: "success",
          icon: "CheckIcon",
        });
        this.itemModalInfo.loading = false;
        this.$nextTick(()=>{
          this.$refs['item-action-modal'].hide()
        })
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(()=>{
        this.itemModalInfo.loading = false;
      })
    },
    deleteWebhook(){
      this.itemModalInfo.loading = true

      this.$store
      .dispatch("transmissionWebhook/deleteWebhook", {transmissionID: this.$route.params.transmissionID, webhookID: this.itemModalInfo.item.id})
      .then((resp) => {
        makeToast({
          title: 'Success!',
          text: 'Webhook deleted successfully',
          variant: "success",
          icon: "CheckIcon",
        });
        this.itemModalInfo.loading = false
        this.$nextTick(()=>{
          this.$refs['item-action-modal'].hide()
        })
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(()=>{
        this.itemModalInfo.loading = false
      })
    }

  },
};
</script>

<style lang="scss" >

.fixed-width-opts{
  min-width: 150px !important;
}


.trigger-info-container{
  background-color: var(--dark-blue);
  padding: 10px;
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  .info-url{
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: 400;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    padding: 4px 10px 4px 4px;
    span{
      user-select: all;
    }
  }
  .inner-container{
    border: 1px solid rgba(0, 0, 0, 0.4);
    box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.3);
    margin-top: 5px;
    border-radius: 4px;
    padding-left: 8px;
    background-color: rgba(255, 255, 255, 0.02);
    padding: 5px;
    .key-value-info{
      letter-spacing: 1px;
      font-family: 'Courier New', Courier, monospace;
      color: rgb(194, 194, 194);
    }
  }
}


</style>