export default class EnumReleaseTags {
  constructor() {
      this.items = [
          {
              "id": 1,
              "label": "enum.webhook.status.inactive",
              "variant": "light-danger"
          },
          {
              "id": 2,
              "label": "enum.webhook.status.active",
              "variant": "light-success"
          },
      ];
  }
}